<template>
  <div id="annual-report">
    <h4 class="mb-0">Public Water System Report</h4>
    (Formerly known as the Annual Report)
    <div class="mt-2">
      <b-button variant="outline-primary" v-b-modal.ap-modal-instructions
        >View Instructions</b-button
      >
      <b-button
        class="ml-3"
        v-if="ar_data"
        variant="outline-primary"
        v-b-modal.ap-modal-load-ar-data
        >Recover Saved Data</b-button
      >
      <b-modal
        ok-only
        id="ap-modal-instructions"
        title="PWS Report Instructions"
      >
        <p>
          Please complete every section of this form. Data is not submitted to
          MSDH until it is signed using the Signature section of this
          application and the Sign and Send button is pressed. (<span style="color:red">*</span>) indicates a required
          field.
        </p>
      </b-modal>
      <b-modal
        id="ap-modal-load-ar-data"
        title="Recover PWS Report Data"
        @ok="loadARData"
      >
        <p>
          Data from your previous session was saved in your web browser. If you
          would like to recover this data, click OK. Otherwise to continue
          working with data pulled from the MSDH database, click Cancel.
        </p>
      </b-modal>
    </div>
    <div id="pws_form">
      <validation-observer
        ref="obs1"
        v-slot="{ invalid, errors, handleSubmit }"
      >
        <b-form id="form1" @submit.stop.prevent="handleSubmit(onSubmit)">
          <h5 class="mt-2">Progress</h5>
          <b-progress
            :value="progress_value"
            show-progress
            class="mb-3"
          ></b-progress>
          <b-card no-body>
            <b-tabs v-model="tab_index" card @input="onChangeTabs">
              <!--<b-tab title="Public Water System" active>-->
              <b-tab title="Step 1" active>
                <b-card-text
                  >I. General Public Water System Information</b-card-text
                >
                <b-form-row>
                  <b-col>
                    <b-form-group
                      id="fs-pws-id"
                      description="7 Digit State ID"
                      label="PWS ID"
                      label-for="pws-id"
                    >
                      <b-form-input
                        id="pws-id"
                        disabled
                        type="number"
                        v-model="pws_id"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      id="fs-pws-type"
                      label="System Type"
                      label-for="pws-type"
                    >
                      <b-form-select
                        id="pws-type"
                        disabled
                        v-model="form1.pws_type"
                        :options="pws_type_options"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>
                </b-form-row>

                <validation-provider
                  name="PWS Name"
                  v-slot="validationContext"
                  rules="required|max:40"
                >
                  <b-form-group
                    id="fs-pws-name"
                    description=""
                    class="required"
                    required
                    label="PWS Name"
                    label-for="pws-name"
                  >
                    <b-form-input
                      id="pws-name"
                      :state="getValidationState(validationContext)"
                      :formatter="formatUpperCase"
                      v-model="form1.pws_name"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Email Address"
                  v-slot="validationContext"
                  rules="email|required"
                >
                  <b-form-group
                    id="fs-email-address"
                    description="Used for electronic sample results and other time-sensitive messages."
                    label="Email Address"
                    label-for="email-address"
                    class="required"
                  >
                    <b-form-input
                      id="email-address"
                      type="email"
                      v-model="form1.email_address"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Street Address"
                  v-slot="validationContext"
                  rules="required|max:40"
                >
                  <b-form-group
                    id="fs-street-address"
                    description=""
                    label="Street Address"
                    label-for="street-address"
                    class="required"
                  >
                    <b-form-input
                      id="street-address"
                      v-model="form1.pws_street"
                      :formatter="formatUpperCase"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="PWS City"
                  v-slot="validationContext"
                  rules="required|max:40"
                >
                  <b-form-group
                    id="fs-pws-city"
                    label="PWS City"
                    label-for="pws-city"
                    class="required"
                  >
                    <b-form-input
                      id="pws-city"
                      v-model="form1.pws_city"
                      :formatter="formatUpperCase"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <b-form-row>
                  <b-col>
                    <validation-provider
                      name="PWS State"
                      v-slot="validationContext"
                      rules="required"
                    >
                      <b-form-group
                        id="fs-pws-state"
                        label="State"
                        label-for="pws-state"
                        class="required"
                      >
                        <b-form-select
                          id="pws-state"
                          v-model="form1.pws_state"
                          :options="state_options"
                          :state="getValidationState(validationContext)"
                        ></b-form-select>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <b-col>
                    <validation-provider
                      name="PWS Zip Code"
                      v-slot="validationContext"
                      rules="zipcode|required"
                    >
                      <b-form-group
                        id="fs-pws-zip"
                        description="ZIP+4 code (e.g.,12345-6789)"
                        label="PWS Zip Code"
                        class="required"
                        label-for="pws-zip"
                      >
                        <b-form-input
                          id="pws-zip"
                          v-model="form1.pws_zip"
                          :state="getValidationState(validationContext)"
                        ></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-form-row>

                <validation-provider
                  name="PWS County"
                  v-slot="validationContext"
                  rules="required"
                >
                  <b-form-group
                    id="fs-pws-county"
                    label="County"
                    label-for="pws-county"
                    class="required"
                  >
                    <b-form-select
                      id="pws-county"
                      v-model="form1.pws_county"
                      :options="county_options"
                      :state="getValidationState(validationContext)"
                    ></b-form-select>
                  </b-form-group>
                </validation-provider>

                <b-container class="pl-0 pr-0 mb-2">
                  <b-row align-v="center">
                    <b-col>
                      <validation-provider
                        name="Population"
                        immediate
                        v-slot="validationContext"
                        rules="required"
                      >
                        <b-form-group
                          id="fs-population"
                          description=""
                          label="Population Served"
                          label-for="population"
                          class="required"
                        >
                          <b-form-input
                            id="population"
                            v-model="form1.population"
                            :state="getValidationState(validationContext)"
                            type="number"
                          ></b-form-input>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col md="auto">
                      <b-button
                        variant="outline-primary"
                        class="mt-3"
                        @click="calculatePopulation"
                        >Calculate Population from Connections</b-button
                      >
                    </b-col>
                  </b-row>
                </b-container>

                <validation-provider
                  name="Connections Metered"
                  v-slot="validationContext"
                  rules="required"
                >
                  <b-form-group
                    id="fs-connections-metered"
                    description="# of metered connections"
                    label="Metered Connections"
                    label-for="connections-metered"
                    class="required"
                  >
                    <b-form-input
                      id="connections-metered"
                      v-model="form1.connections_metered"
                      @change="checkMeteredConnections"
                      type="number"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Connections Metered Residential"
                  v-slot="validationContext"
                  rules="required"
                >
                  <b-form-group
                    id="fs-connections-metered-residential"
                    description="# of metered connections that are residential"
                    label="Metered Connections (Residential)"
                    label-for="connections-metered-residential"
                    class="required"
                  >
                    <b-form-input
                      id="connections-metered-residential"
                      v-model="form1.connections_metered_residential"
                      @change="checkMeteredConnections"
                      :state="getValidationState(validationContext)"
                      type="number"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Connections Unmetered"
                  v-slot="validationContext"
                  rules="required"
                >
                  <b-form-group
                    id="fs-connections-unmetered"
                    description="# of unmetered connections"
                    label="Unmetered Connections"
                    label-for="connections-unmetered"
                    class="required"
                  >
                    <b-form-input
                      id="connections-unmetered"
                      v-model="form1.connections_unmetered"
                      @change="checkUnmeteredConnections"
                      :state="getValidationState(validationContext)"
                      type="number"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Connections Unmetered Residential"
                  v-slot="validationContext"
                  rules="required"
                >
                  <b-form-group
                    id="fs-connections-unmetered-residential"
                    description="# of unmetered connections that are residential"
                    label="Unmetered Connections (Residential)"
                    label-for="connections-unmetered-residential"
                    class="required"
                  >
                    <b-form-input
                      id="connections-unmetered-residential"
                      @change="checkUnmeteredConnections"
                      v-model="form1.connections_unmetered_residential"
                      :state="getValidationState(validationContext)"
                      type="number"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <b-form-group
                  id="fs-connections-calculated"
                  description="# of total calculated connections"
                  label="Calculated Total Connections"
                  label-for="connections-total"
                >
                  <b-form-input
                    id="connections-total"
                    readonly
                    v-model="form1.connections_total"
                    type="number"
                  ></b-form-input>
                </b-form-group>
              </b-tab>

              <!--<b-tab title="Administrative Contact">-->
              <b-tab title="Step 2">
                <b-card-text>II. Administrative Contact</b-card-text>

                <validation-provider
                  name="Administrator Name, Title"
                  v-slot="validationContext"
                  rules="max:40"
                >
                  <b-form-group
                    id="s2-fs-admin-name"
                    label="Administrator Name, Title"
                    description="First Last, Title"
                    label-for="s2-admin-name"
                  >
                    <b-form-input
                      id="s2-admin-name"
                      v-model="form2.admin_name"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <validation-provider v-slot="validationContext" rules="email">
                  <b-form-group
                    id="s2_fg_email_address"
                    label="Email address:"
                    label-for="section2_email_address"
                  >
                    <b-form-input
                      id="s2_email_address"
                      v-model="form2.email_address"
                      type="email"
                      :state="getValidationState(validationContext)"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  v-slot="validationContext"
                  :rules="{ regex: /^[2-9]\d{2}-?[2-9]\d{2}-?\d{4}$/ }"
                >
                  <b-form-group
                    id="s2-phone-fg"
                    label="Phone Number"
                    label-for="s2-phone"
                    description
                  >
                    <b-input
                      id="s2-phone"
                      type="tel"
                      :state="getValidationState(validationContext)"
                      v-model="form2.phone"
                      @blur="formatPhoneNumber"
                    />
                  </b-form-group>
                </validation-provider>
              </b-tab>

              <!--<b-tab title="Legally Responsible Official">-->
              <b-tab title="Step 3">
                <b-card-text>III. Legally Responsible Official<br /><br />
                  <strong>Restrictions:</strong> The name and contact information for this person is unique in our system.  If this person is associated with different water systems, he or she must have exactly the same information throughout.
                </b-card-text>
                <b-form-row>
                  <b-col>
                    <validation-provider
                      name="Legally Responsible Official First Name"
                      v-slot="validationContext"
                      immediate
                      rules="max:20"
                    >
                      <b-form-group
                        id="fs-s3-first-name"
                        label="First Name"
                        label-for="s3-first-name"
                      >
                        <b-form-input
                          id="s3-first-name"
                          type="text"
                          :state="getValidationState(validationContext)"
                          v-model="form3.first_name"
                        ></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col>
                    <validation-provider
                      name="Legally Responsible Official Last Name"
                      v-slot="validationContext"
                      immediate
                      rules="max:20"
                    >
                      <b-form-group
                        id="fs-s3-last-name"
                        label="Last Name"
                        label-for="s3-last-name"
                      >
                        <b-form-input
                          id="s3-last-name"
                          type="text"
                          :state="getValidationState(validationContext)"
                          v-model="form3.last_name"
                        ></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-form-row>

                <validation-provider
                  name="Legally Responsible Official Title"
                  v-slot="validationContext"
                  immediate
                  rules="max:20"
                >
                  <b-form-group
                    id="fs-s3-title"
                    description
                    label="Title"
                    label-for="s3-title"
                  >
                    <b-form-input
                      id="s3-title"
                      type="text"
                      :state="getValidationState(validationContext)"
                      v-model="form3.title"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <b-form-group
                  id="fs-s3-email-address"
                  description
                  label="Email Address"
                  label-for="s3-email-address"
                >
                  <b-form-input
                    id="s3-email-address"
                    type="email"
                    v-model="form3.email_address"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="fs-s3-street-address"
                  description
                  label="Street Address"
                  label-for="s3-street-address"
                >
                  <b-form-input
                    id="s3-street-address"
                    v-model="form3.street_address"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="fs-s3-city"
                  description
                  label="City"
                  label-for="s3-city"
                >
                  <b-form-input
                    id="s3-city"
                    v-model="form3.city"
                    maxlength="30"
                  ></b-form-input>
                </b-form-group>

                <b-form-row>
                  <b-col>
                    <b-form-group
                      id="fs-s3-state"
                      label="State"
                      label-for="s3-state"
                    >
                      <b-form-select
                        id="s3-state"
                        v-model="form3.state"
                        :options="state_options"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col>
                    <validation-provider
                      name="Zip Code"
                      v-slot="validationContext"
                      rules="zipcode"
                    >
                      <b-form-group
                        id="fs-s3-zip"
                        description="ZIP+4 code (e.g.,12345-6789)"
                        label="Zip"
                        label-for="s3-zip"
                      >
                        <b-form-input
                          id="s3-zip"
                          :state="getValidationState(validationContext)"
                          v-model="form3.zip"
                        ></b-form-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-form-row>

                <validation-provider
                  name="Official Business Phone"
                  v-slot="validationContext"
                  :rules="{ regex: /^[2-9]\d{2}-?[2-9]\d{2}-?\d{4}$/ }"
                >
                  <b-form-group
                    id="fs-s3-bus-phone"
                    label="Business Phone Number"
                    label-for="s3-bus-phone"
                    description
                  >
                    <b-input
                      id="s3-bus-phone"
                      type="tel"
                      :state="getValidationState(validationContext)"
                      v-model="form3.phone_bus"
                      @blur="formatPhoneNumber"
                    />
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Official Alt Business Phone"
                  v-slot="validationContext"
                  :rules="{ regex: /^[2-9]\d{2}-?[2-9]\d{2}-?\d{4}$/ }"
                >
                  <b-form-group
                    id="fs-s3-bus-phone-alt"
                    label="Alternate Business Phone Number"
                    label-for="s3-bus-phone-alt"
                    description
                  >
                    <b-input
                      id="s3-bus-phone-alt"
                      type="tel"
                      :state="getValidationState(validationContext)"
                      v-model="form3.phone_bus_alt"
                      @blur="formatPhoneNumber"
                    />
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Official Home Phone"
                  v-slot="validationContext"
                  :rules="{ regex: /^[2-9]\d{2}-?[2-9]\d{2}-?\d{4}$/ }"
                >
                  <b-form-group
                    id="fs-s3-home-phone"
                    label="Home Phone Number"
                    label-for="s3-home-phone"
                    description
                  >
                    <b-input
                      id="s3-home-phone"
                      type="tel"
                      :state="getValidationState(validationContext)"
                      v-model="form3.phone_home"
                      @blur="formatPhoneNumber"
                    />
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Official Mobile Phone"
                  v-slot="validationContext"
                  :rules="{ regex: /^[2-9]\d{2}-?[2-9]\d{2}-?\d{4}$/ }"
                >
                  <b-form-group
                    id="fs-s3-mobile-phone"
                    label="Mobile Phone Number"
                    label-for="s3-mobile-phone"
                    description
                  >
                    <b-input
                      id="s3-mobile-phone"
                      type="tel"
                      :state="getValidationState(validationContext)"
                      v-model="form3.phone_mobile"
                      @blur="formatPhoneNumber"
                    />
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Official Fax"
                  v-slot="validationContext"
                  :rules="{ regex: /^[2-9]\d{2}-?[2-9]\d{2}-?\d{4}$/ }"
                >
                  <b-form-group
                    id="fs-s3-fax"
                    label="Fax Number"
                    label-for="s3-fax"
                    description
                  >
                    <b-input
                      id="s3-fax"
                      type="tel"
                      :state="getValidationState(validationContext)"
                      v-model="form3.fax"
                      @blur="formatPhoneNumber"
                    />
                  </b-form-group>
                </validation-provider>

                <!-- BACT Address -->
                <b-card
                  header="BACT sample results (Mailing Address)"
                  class="mt-5"
                >
                  <validation-provider
                    name="Bacti Address Name"
                    v-slot="validationContext"
                    rules="max:40"
                  >
                    <b-form-group
                      id="fs-s3-bact-name"
                      description="Whose attention do these results need to be addressed to?"
                      label="Name or Title"
                      label-for="s3-bact-name"
                    >
                      <b-form-input
                        id="s3-bact-name"
                        :state="getValidationState(validationContext)"
                        v-model="form3.bact_name"
                      ></b-form-input>
                    </b-form-group>
                  </validation-provider>

                  <b-form-group
                    id="fs-s3-bact-street-address"
                    description
                    label="Street Address"
                    label-for="s3-bact-street-address"
                  >
                    <b-form-input
                      id="s3-bact-street-address"
                      v-model="form3.bact_street"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="fs-s3-bact-city"
                    description
                    label="City"
                    label-for="s3-bact-city"
                  >
                    <b-form-input
                      id="s3-bact-city"
                      v-model="form3.bact_city"
                      maxlength="30"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-row>
                    <b-col>
                      <b-form-group
                        id="fs-s3-bact-state"
                        label="State"
                        label-for="s3-bact-state"
                      >
                        <b-form-select
                          id="s3-bact-state"
                          v-model="form3.bact_state"
                          :options="state_options"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <validation-provider
                        name="Bacti Zip Code"
                        v-slot="validationContext"
                        rules="zipcode"
                      >
                        <b-form-group
                          id="fs-s3-bact-zip"
                          description="ZIP+4 code (e.g.,12345-6789)"
                          label="Zip"
                          label-for="s3-bact-zip"
                        >
                          <b-form-input
                            id="s3-bact-zip"
                            v-model="form3.bact_zip"
                            :state="getValidationState(validationContext)"
                          ></b-form-input>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-form-row>
                </b-card>
                <!-- End Bacti Address -->

                <!-- Delivery Address -->
                <b-card
                  header="Delivery Sample Kit (Shipping Address)"
                  class="mt-4"
                >
                  <validation-provider
                    name="Delivery Address Name"
                    v-slot="validationContext"
                    rules="max:40"
                  >
                    <b-form-group
                      id="fs-s3-delivery-name"
                      description="Whose attention do these results need to be addressed to?"
                      label="Name"
                      label-for="s3-delivery-name"
                    >
                      <b-form-input
                        id="s3-delivery-name"
                        :state="getValidationState(validationContext)"
                        v-model="form3.delivery_name"
                      ></b-form-input>
                    </b-form-group>
                  </validation-provider>

                  <b-form-group
                    id="fs-s3-delivery-street-address"
                    description
                    label="Street Address (No P.O. Box)"
                    label-for="s3-delivery-street-address"
                  >
                    <b-form-input
                      id="s3-delivery-street-address"
                      v-model="form3.delivery_street"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group
                    id="fs-s3-delivery-city"
                    description
                    label="City"
                    label-for="s3-delivery-city"
                  >
                    <b-form-input
                      id="s3-delivery-city"
                      v-model="form3.delivery_city"
                      maxlength="30"
                    ></b-form-input>
                  </b-form-group>

                  <b-form-row>
                    <b-col>
                      <b-form-group
                        id="fs-s3-delivery-state"
                        label="State"
                        label-for="s3-delivery-state"
                      >
                        <b-form-select
                          id="s3-delivery-state"
                          v-model="form3.delivery_state"
                          :options="state_options"
                        ></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col>
                      <validation-provider
                        name="Delivery Zip Code"
                        v-slot="validationContext"
                        rules="zipcode"
                      >
                        <b-form-group
                          id="fs-s3-delivery-zip"
                          description="ZIP+4 code (e.g.,12345-6789)"
                          label="Zip"
                          label-for="s3-delivery-zip"
                        >
                          <b-form-input
                            id="s3-delivery-zip"
                            v-model="form3.delivery_zip"
                            :state="getValidationState(validationContext)"
                          ></b-form-input>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-form-row>
                </b-card>
                <!--</b-form>-->
              </b-tab>

              <!--<b-tab title="Designated Operator">-->
              <b-tab title="Step 4">
                <b-card-text>IV. Designated Operator</b-card-text>
                <p>
                  The designated operator is the person who directly supervises
                  and is personally responsible for the daily operation and
                  maintenance of this public water system, and must hold a valid
                  Certificate of Competency as required by Section 21-27-201
                  through 21-27-221, Mississippi Code of 1972, Annotated. The
                  personal residence of the designated operator should be within
                  <strong>50 miles</strong> of this Public Water System.
                </p>
                <b-form-group
                  id="fs-s4-first-name"
                  description="(As issued on the MSDH certificate)"
                  label="First Name"
                  label-for="s4-first-name"
                >
                  <b-form-input
                    id="s4-first-name"
                    type="text"
                    v-model="form4.first_name"
                  ></b-form-input>
                </b-form-group>
                <b-form-group
                  id="fs-s4-last-name"
                  description="(As issued on the MSDH certificate)"
                  label="Last Name"
                  label-for="s4-last-name"
                >
                  <b-form-input
                    id="s4-last-name"
                    type="text"
                    v-model="form4.last_name"
                  ></b-form-input>
                </b-form-group>

                <validation-provider
                  name="Operator Certificate Number"
                  v-slot="validationContext"
                  rules="certno"
                >
                <b-form-group
                  id="fs-s4-certno"
                  description
                  label="Certificate Number"
                  label-for="s4-certno"
                >
                  <b-form-input
                    id="s4-certno"
                    type="text"
                    :state="getValidationState(validationContext)"
                    v-model="form4.cert_no"
                  ></b-form-input>
                </b-form-group>
                </validation-provider>

                <validation-provider
                  name="Operator Distance"
                  v-slot="validationContext"
                  :rules="form4.cert_no ? 'required|integer' : 'integer'"
                >
                  <b-form-group
                    id="fs-s4-distance"
                    description="What is the distance in miles from your home address to the physical location of this public water system?"
                    label="Distance to PWS"
                    label-for="s4-distance"
                  >
                    <b-form-input
                      id="s4-distance"
                      :state="getValidationState(validationContext)"
                      type="number"
                      v-model="form4.distance"
                    ></b-form-input>
                  </b-form-group>
                </validation-provider>

                <b-button v-b-modal.modal-operator-search class="mt-3"
                  >Lookup Operator</b-button
                >
                <b-modal
                  id="modal-operator-search"
                  @ok="handleOperatorSearch"
                  title="Operator Search"
                >
                  <b-form-group
                    id="fs-opsearch-last-name"
                    label="Last Name"
                    label-for="opsearch-last-name"
                  >
                    <b-form-input
                      id="opsearch-last-name"
                      type="text"
                      v-model="opsearch_last_name"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group
                    id="fs-operator-lookup"
                    label="Search Results"
                    label-for="operator-lookup"
                  >
                    <b-form-select
                      id="operator-lookup"
                      v-model="operator_selected"
                      :options="operator_options"
                    ></b-form-select>
                  </b-form-group>
                  <b-button @click="fetchOperatorData" class="mt-3"
                    >Search</b-button
                  >
                </b-modal>

                <!--
              <h5>Operator Search</h5>
              <p>
                Search for an operator by entering either 'Cert No.', 'Last
                Name', or 'First Name' in the fields above. Populate just one field
                to search and entering the first part of a name will return
                more results. For instance, entering 'HOL' in 'Last Name' will
                return both HOLMES AND HOLDER. If the search returns results,
                select one to populate the fields above.
              </p>
              -->
                <!--</b-form>-->
              </b-tab>

              <b-tab title="Review and Sign" id="review-and-sign">
                <b-card-text>
                  Review your information, then sign at the bottom.
                </b-card-text>
                <b-alert :show="invalid" variant="danger">
                  There are errors that must be corrected.
                  <ul>
                    <template v-for="(error, name, index) in errors">
                      <li v-if="error.length > 0" :key="index">
                        {{ name }}
                        {{ error }}
                        {{ index }}
                      </li>
                    </template>
                  </ul>
                </b-alert>

                <div v-if="show_review && !invalid">
                  <h5>I. General Public Water System Information</h5>
                  <table v-if="!invalid" class="my-4">
                    <tr v-for="fn in form1_names" :key="fn[0]">
                      <th class="text-muted pr-4 small">{{ fn[0] }}</th>
                      <td class="small">{{ form1[fn[1]] }}</td>
                    </tr>
                  </table>

                  <h5>II. Administrative Contact</h5>
                  <table class="my-4">
                    <tr v-for="fn in form2_names" :key="fn[0]">
                      <th class="text-muted pr-4 small">{{ fn[0] }}</th>
                      <td class="small">{{ form2[fn[1]] }}</td>
                    </tr>
                  </table>

                  <h5>III. Legally Responsible Official</h5>
                  <table class="my-4">
                    <tr v-for="fn in form3_names" :key="fn[0]">
                      <th class="text-muted pr-4 small">{{ fn[0] }}</th>
                      <td class="small">{{ form3[fn[1]] }}</td>
                    </tr>
                  </table>

                  <h6>Mailing Address for Bacteriological Samples Results</h6>
                  <table class="my-4">
                    <tr v-for="fn in form3_b_names" :key="fn[0]">
                      <th class="text-muted pr-4 small">{{ fn[0] }}</th>
                      <td class="small">{{ form3[fn[1]] }}</td>
                    </tr>
                  </table>

                  <h6>Delivery (Shipping) Address</h6>
                  <table class="my-4">
                    <tr v-for="fn in form3_d_names" :key="fn[0]">
                      <th class="text-muted pr-4 small">{{ fn[0] }}</th>
                      <td class="small">{{ form3[fn[1]] }}</td>
                    </tr>
                  </table>

                  <h5>IV. Designated Operator</h5>
                  <table class="my-4">
                    <tr v-for="fn in form4_names" :key="fn[0]">
                      <th class="text-muted pr-4 small">{{ fn[0] }}</th>
                      <td class="small">{{ form4[fn[1]] }}</td>
                    </tr>
                  </table>

                  <b-form>
                    <b-form-group
                      label="Your Name for Signature (Required)"
                      label-form="signer"
                    >
                      <b-form-input
                        type="text"
                        id="signer"
                        v-model="signame"
                        required
                        placeholder=""
                      >
                      </b-form-input>
                    </b-form-group>
                    <b-alert
                      :show="!submitting_data && !data_submitted"
                      variant="warning"
                      class="mt-3"
                    >
                    No data is sent until you click the "Sign and Send" button. If successful, you will see a green confirmation message.
                    </b-alert>
                    <p>
                      Signature: &nbsp;<span class="signature">{{
                        signame
                      }}</span>
                    </p>
                    <!--
                    <b-button @click="generateKey" variant="primary"
                      >Create Signing Key</b-button
                    >
                      -->
                    <b-button
                      :disabled="!signame || submitting_data || data_submitted"
                      variant="primary"
                      @click="signDoc"
                      ><b-icon icon="pen"></b-icon>
                      <span v-if="!submitting_data">&nbsp; Sign and Send</span>
                      <span v-if="submitting_data"
                        >&nbsp;
                        <b-spinner label="Sending"></b-spinner>
                      </span>
                    </b-button>
                    <p v-if="sigkey" class="mt-3">
                      <b-icon icon="key"></b-icon>
                      &nbsp; Using Crypto Key
                      <span>{{ sigkey.key.publicKey.algorithm.name }}</span>
                      &nbsp;
                      <span>{{
                        sigkey.key.publicKey.algorithm.namedCurve
                      }}</span>
                      created on {{ sigkey.dt_created }}
                    </p>
                  </b-form>
                  <p v-if="data_signature_ts">
                    Digital Signature: {{ data_signature }}
                    <br />
                    {{ data_signature_ts }}
                  </p>
                  <b-alert :show="data_submitted" variant="success">
                    Data submission successful. Thank you! The Water System Report is
                    complete unless you want to make changes and resubmit.<br />
                    <b-icon icon="envelope"></b-icon>&nbsp; You should receive an
                    email confirmation soon.
                    </b-alert>
                  <b-alert :show="data_submitted" varitant="info">
                    <b-icon icon="info-circle"></b-icon>&nbsp;
                    Don't forget to update your
                    <b-link to="/board-members">Board Members</b-link>.
                  </b-alert>
                  <b-button v-if="data_submitted"
                    variant="primary"
                    class="mt-3"
                    @click="printDiv"
                    >Print this Page</b-button
                  >

                  <!--
                  <b-button v-if="data_signature_ts && !data_submitted"
                    variant="primary" @click="resendData"
                    class="mb-3"
                  ><b-icon icon="arrow-repeat"></b-icon>&nbsp; Retry Sending Data
                  </b-button>
                  -->

                  <b-alert
                    :show="
                      data_signature_ts && !data_submitted && !submitting_data
                    "
                    variant="danger"
                  >
                    Error submitting data. This is an unexpected error and will
                    be looked into. You can try to submit the data again
                    including any corrections.
                  </b-alert>
                </div>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-form>
      </validation-observer>
    </div>

    <b-container>
      <b-row class="my-4 pb-4">
        <b-col>
          <b-button
            :disabled="form_previous_disabled"
            @click="tab_index--"
            href="#pws_form"
            variant="primary"
            >Previous</b-button
          >
        </b-col>
        <b-col>
          <b-button @click="showResetModal" variant="secondary">Reset</b-button>
        </b-col>
        <b-col cols="auto">
          <b-button
            :disabled="form_next_disabled"
            @click="tab_index++"
            href="#pws_form"
            variant="primary"
            >Next</b-button
          >
          <!--<b-button variant="primary">Save Changes</b-button>-->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { set, get, del } from "idb-keyval"

// Base64 encode
function encode64(buff) {
  return btoa(
    new Uint8Array(buff).reduce((s, b) => s + String.fromCharCode(b), "")
  )
}

function textEncode(str) {
  if (window.TextEncoder) {
    return new TextEncoder("utf-8").encode(str)
  }
  var utf8 = unescape(encodeURIComponent(str))
  var result = new Uint8Array(utf8.length)
  for (var i = 0; i < utf8.length; i++) {
    result[i] = utf8.charCodeAt(i)
  }
  return result
}

// Hash function in javascript
//function hash(algo, str) {
//  return crypto.subtle.digest(algo, new TextEncoder().encode(str))
//}

export default {
  name: "AnnualReport",
  data() {
    return {
      app_mounted: false,
      tab_index: null,
      record_pws_data: null,
      b64_public_key: null,
      signed_data: null,
      data_signature: null,
      data_signature_ts: null,
      submitting_data: false,
      data_submitted: false,
      // data from last visit
      ar_data: null,
      form1_names: [
        ["PWS Name", "pws_name"],
        ["PWS Type", "pws_type"],
        ["Email", "email_address"],
        ["Street Address", "pws_street"],
        ["City", "pws_city"],
        ["State", "pws_state"],
        ["Zip", "pws_zip"],
        ["County", "pws_county"],
        ["Population", "population"],
        ["Metered Connections", "connections_metered"],
        [
          "Metered Connections (Residential)",
          "connections_metered_residential"
        ],
        ["Unmetered Connections", "connections_unmetered"],
        [
          "Unmetered Connections (Residential)",
          "connections_unmetered_residential"
        ],
        ["Total Connections", "connections_total"]
      ],
      form2_names: [
        ["Name, Title", "admin_name"],
        ["Email Address", "email_address"],
        ["Phone Number", "phone"]
      ],
      form4_names: [
        ["First Name", "first_name"],
        ["Last Name", "last_name"],
        ["Certificate Number", "cert_no"],
        ["Distance to PWS", "distance"]
      ],
      form1: {
        pws_name: null,
        pws_county: null,
        pws_street: null,
        pws_city: null,
        pws_state: null,
        pws_zip: null,
        pws_type: null,
        population: null,
        email_address: null,
        connections_metered: 0,
        connections_metered_residential: 0,
        connections_unmetered: 0,
        connections_unmetered_residential: 0,
        connections_total: 0
      },
      form2: {
        admin_name: null,
        email_address: null,
        phone: null
      },
      form3_names: [
        ["First Name", "first_name"],
        ["Last Name", "last_name"],
        ["Title", "title"],
        ["Email", "email_address"],
        ["Street", "street_address"],
        ["City", "city"],
        ["State", "state"],
        ["Zip", "zip"],
        ["Business Phone", "phone_bus"],
        ["Business Phone Alt", "phone_bus_alt"],
        ["Home Phone", "phone_home"],
        ["Mobile Phone", "phone_mobile"]
      ],
      form3_b_names: [
        ["Name", "bact_name"],
        ["Street", "bact_street"],
        ["City", "bact_city"],
        ["State", "bact_state"],
        ["Zip", "bact_zip"]
      ],
      form3_d_names: [
        ["Name", "delivery_name"],
        ["Street", "delivery_street"],
        ["City", "delivery_city"],
        ["State", "delivery_state"],
        ["Zip", "delivery_zip"]
      ],
      form3: {
        first_name: null,
        last_name: null,
        title: null,
        email_address: null,
        street_address: null,
        city: null,
        state: "MS",
        zip: null,
        phone_bus: null,
        phone_bus_alt: null,
        phone_home: null,
        phone_mobile: null,
        fax: null,
        bact_name: null,
        bact_street: null,
        bact_city: null,
        bact_state: "MS",
        bact_zip: null,
        delivery_name: null,
        delivery_city: null,
        delivery_state: "MS",
        delivery_zip: null
      },
      form4: {
        first_name: null,
        last_name: null,
        cert_no: null,
        distance: null
      },
      signame: null,
      sigkey: null,
      operator_options: [],
      operator_dataset: {},
      operator_selected: null,
      opsearch_last_name: null,
      opsearch_cert_no: null,
      pws_type_options: [
        { text: "Community", value: "C" },
        { text: "Non Community", value: "NC" },
        { text: "Non Public", value: "NP" },
        { text: "Transient", value: "NTNC" }
      ],
      state_options: [
        { text: "", value: null },
        "MS",
        "AK",
        "AL",
        "AR",
        "AZ",
        "CA",
        "CO",
        "CT",
        "DE",
        "FL",
        "GA",
        "HI",
        "IA",
        "ID",
        "IL",
        "IN",
        "KS",
        "KY",
        "LA",
        "MA",
        "MD",
        "ME",
        "MI",
        "MN",
        "MO",
        "MT",
        "NC",
        "ND",
        "NE",
        "NH",
        "NJ",
        "NM",
        "NV",
        "NY",
        "OH",
        "OK",
        "OR",
        "PA",
        "RI",
        "SC",
        "SD",
        "TN",
        "TX",
        "UT",
        "VA",
        "VT",
        "WA",
        "WI",
        "WV",
        "WY"
      ],
      county_options: [
        { text: "", value: null },
        "ADAMS",
        "ALCORN",
        "AMITE",
        "ATTALA",
        "BENTON",
        "BOLIVAR",
        "CALHOUN",
        "CARROLL",
        "CHICKASAW",
        "CHOCTAW",
        "CLAIBORNE",
        "CLARKE",
        "CLAY",
        "COAHOMA",
        "COPIAH",
        "COVINGTON",
        "DESOTO",
        "FORREST",
        "FRANKLIN",
        "GEORGE",
        "GREENE",
        "GRENADA",
        "HANCOCK",
        "HARRISON",
        "HINDS",
        "HOLMES",
        "HUMPHREYS",
        "ISSAQUENA",
        "ITAWAMBA",
        "JACKSON",
        "JASPER",
        "JEFFERSON",
        "JEFF DAVIS",
        "JONES",
        "KEMPER",
        "LAFAYETTE",
        "LAMAR",
        "LAUDERDALE",
        "LAWRENCE",
        "LEAKE",
        "LEE",
        "LEFLORE",
        "LINCOLN",
        "LOWNDES",
        "MADISON",
        "MARION",
        "MARSHALL",
        "MONROE",
        "MONTGOMERY",
        "NESHOBA",
        "NEWTON",
        "NOXUBEE",
        "OKTIBBEHA",
        "PANOLA",
        "PEARL RIVER",
        "PERRY",
        "PIKE",
        "PONTOTOC",
        "PRENTISS",
        "QUITMAN",
        "RANKIN",
        "SCOTT",
        "SHARKEY",
        "SIMPSON",
        "SMITH",
        "STONE",
        "SUNFLOWER",
        "TALLAHATCHIE",
        "TATE",
        "TIPPAH",
        "TISHOMINGO",
        "TUNICA",
        "UNION",
        "WALTHALL",
        "WARREN",
        "WASHINGTON",
        "WAYNE",
        "WEBSTER",
        "WILKINSON",
        "WINSTON",
        "YALOBUSHA",
        "YAZOO"
      ],
    }
  },
  //persist: ["form1", "form2", "form3", "form4", "board_members"],
  computed: {
    pws_id: function() {
      return this.$store.state.pws_id
    },
    progress_value: function() {
      return Math.round(this.tab_index * 20)
    },
    show_review: function() {
      // obs1 may not be defined yet
      if (!this.app_mounted) {
        return
      }
      return !this.$refs.obs1.invalid
    },
    form_previous_disabled: function() {
      return this.tab_index < 1 ? true : false
    },
    form_next_disabled: function() {
      return this.tab_index > 3 ? true : false
    }
  },
  created() {
    console.log("created")
    // if not signed in - redirect
    if (!this.$store.state.pws_id) {
      this.$router.push("signin")
      // nothing to get - log out
      console.log("No PWS - Rerouting to Sign In")
    }
    this.fetchRecordData().then(() => {
      // if data isn't set from localStorage, set defaults
      if (!this.form1.pws_name && this.record_pws_data) {
        this.setFormFieldDefaults()
      }
    })
    // check for data from previous visit
    this.checkARData()
    // create the index db
    get("signing_key").then((key) => {
      if (key) {
        this.sigkey = key
        //console.log("name is " + key.name)
        console.log("key is " + key.key)
        console.log("pubkey is " + key.key.publicKey)
        console.log("privkey is " + key.key.privateKey)
        this.exportPublicKey(key.key)
      } else {
        this.generateKey()
      }
    })
  },
  mounted() {
    console.log("form mounted")
    this.app_mounted = true
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      // don't show green checkbox - never set to valid
      if (valid) {
        valid = null
      }
      return dirty || validated ? valid : null
    },
    onChangeTabs(tab_idx) {
      //console.log('tab index ' + tab_idx)
      if (tab_idx == 5) {
        this.revalidate()
      }
    },
    revalidate() {
      //console.log('called revalidate')
      this.$refs.obs1.validate()
    },
    formatUpperCase(value) {
      return String(value).toUpperCase()
    },
    acceptNumber() {
      var x = this.form2.phone_number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
      this.form2.phone_number = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "")
    },
    calculatePopulation() {
      this.form1.population = Math.round(this.form1.connections_total * 2.64)
    },
    calculateTotalConnections() {
      this.form1.connections_total =
        parseInt(this.form1.connections_metered) +
        parseInt(this.form1.connections_unmetered)
    },
    checkMeteredConnections() {
      var cm = this.form1.connections_metered
        ? this.form1.connections_metered
        : 0
      this.form1.connections_metered = Math.max(
        parseInt(cm),
        parseInt(this.form1.connections_metered_residential)
      )
      this.calculateTotalConnections()
    },
    checkUnmeteredConnections() {
      var cn_um = this.form1.connections_unmetered
        ? this.form1.connections_unmetered
        : 0
      this.form1.connections_unmetered = Math.max(
        parseInt(cn_um),
        parseInt(this.form1.connections_unmetered_residential)
      )
      this.calculateTotalConnections()
    },
    formatPhoneNumber(e) {
      if (e.target.value) {
        var x = e.target.value.replace(/\D/g, "").match(/(\d{3})(\d{3})(\d{4})/)
        if (x) {
          e.target.value = x[1] + "-" + x[2] + "-" + x[3]
          e.target.dispatchEvent(new Event("input"))
        }
      }
    },
    formatZIP(s) {
      return s.substr(0, 5)
    },
    onSubmit() {
      //evt.preventDefault()
      console.log("form submitted")
      console.log(this.$refs.obs1)
      console.log(this.$refs.obs1.valid)
      //alert(JSON.stringify(this.$refs.obs1.valid))
    },
    showResetModal() {
      this.$bvModal
        .msgBoxConfirm(
          "Resetting this form will discard all of your changes and load data from MSDH records! Confirm this is what you want."
        )
        .then((value) => {
          if (value) {
            this.setFormFieldDefaults()
            this.resetAllData()
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    resetAllData() {
      // resetting signatures, etc.
      this.signed_data = null
      this.data_signature = null
      this.data_submitted = false
      this.data_signature_ts = null
      this.signame = null
      this.form1.population = null
    },
    fetchRecordData() {
      console.log("called fetchRecordData")
      const path = process.env.VUE_APP_API_URI + "/sdwisdata"
      console.log(path)
      console.log("getting record data", this.$store.state.pws_id)
      return this.axios
        .post(path, { pws_id: this.$store.state.pws_id })
        .then((res) => {
          console.log(res)
          if (res.data.data) {
            this.record_pws_data = res.data.data
            console.log("got data")
          } else {
            console.log("record data fetch failed")
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    handleOperatorSearch() {
      console.log("ha")
      this.form4.cert_no = this.operator_dataset[this.operator_selected][
        "cert_no"
      ]
      this.form4.last_name = this.operator_dataset[this.operator_selected][
        "last_name"
      ]
      this.form4.first_name = this.operator_dataset[this.operator_selected][
        "first_name"
      ]
    },
    fetchOperatorData() {
      const path = process.env.VUE_APP_API_URI + "/pws-operator"
      console.log(path)
      this.axios
        .get(path, {
          params: {
            lastname: this.opsearch_last_name
          }
        })
        .then((res) => {
          console.log(res)
          if (res.data) {
            // save dataset
            this.operator_dataset = res.data
            // first clear the array
            this.operator_options = []
            const noop_idx = res.data.length + 1
            const noop_text = "Select From Results"
            this.operator_options.push({ value: noop_idx, text: noop_text })
            this.operator_selected = noop_idx
            for (var i = 0, size = res.data.length; i < size; i++) {
              var cert_no = res.data[i].cert_no
              var last_name = res.data[i].last_name
              var first_name = res.data[i].first_name
              this.operator_options.push({
                value: i,
                text: cert_no + ", " + last_name + ", " + first_name
              })
            }
            //this.operator_options = res.data
            //console.log("first name: " + res.data.first_name)
            //console.log("last name: " + res.data.last_name)
            //this.form4.first_name = res.data.first_name
            //this.form4.last_name = res.data.last_name
          } else {
            console.log("operator data fetch failed")
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    onReset(evt) {
      evt.preventDefault()
      // Reset our form values
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
    setFormFieldDefaults() {
      // update form
      console.log("setting defaults")
      //if (!this.record_pws_data) {
      //  this.fetchRecordData()
      //}
      this.form1.pws_id = this.$store.state.pws_id
      this.form1.pws_name = this.record_pws_data.pws_name
      this.form1.pws_county = this.record_pws_data.county_name
      this.form1.population = this.record_pws_data.d_population_count
      this.form1.pws_type = this.record_pws_data.d_pws_fed_type_cd
      this.form1.pws_street = this.record_pws_data.phy_street
      this.form1.pws_city = this.record_pws_data.phy_city
      this.form1.pws_state = this.record_pws_data.phy_state
      this.form1.pws_zip = this.record_pws_data.phy_zip
      this.form1.email_address = this.record_pws_data.phy_email
      // connections
      var cn_metered_res = this.record_pws_data.res_metered
        ? this.record_pws_data.res_metered
        : 0
      var cn_metered_cb = this.record_pws_data.cb_metered
        ? this.record_pws_data.cb_metered
        : 0
      var cn_unmetered_res = this.record_pws_data.res_unmetered
        ? this.record_pws_data.res_unmetered
        : 0
      var cn_unmetered_cb = this.record_pws_data.cb_unmetered
        ? this.record_pws_data.cb_unmetered
        : 0
      this.form1.connections_metered_residential = cn_metered_res
      this.form1.connections_metered = cn_metered_res + cn_metered_cb
      this.form1.connections_unmetered_residential = cn_unmetered_res
      this.form1.connections_unmetered = cn_unmetered_res + cn_unmetered_cb
      this.calculateTotalConnections()
      this.form2.admin_name = this.record_pws_data.office_name_title
      this.form2.email_address = this.record_pws_data.office_email
      this.form2.phone = this.record_pws_data.office_phone
      this.form3.first_name = this.record_pws_data.ac_first_name
      this.form3.last_name = this.record_pws_data.ac_last_name
      this.form3.title = this.record_pws_data.ac_job_title
      this.form3.email_address = this.record_pws_data.ac_email
      this.form3.street_address = this.record_pws_data.ac_street
      this.form3.city = this.record_pws_data.ac_city
      this.form3.state = this.record_pws_data.ac_state
      this.form3.zip = this.record_pws_data.ac_zip
      this.form3.phone_bus = this.record_pws_data.ac_phone_bus
      this.form3.phone_bus_alt = this.record_pws_data.ac_phone_bus_alt
      this.form3.phone_home = this.record_pws_data.ac_phone_home
      this.form3.phone_mobile = this.record_pws_data.ac_phone_mobile
      this.form3.fax = this.record_pws_data.ac_fax
      this.form3.bact_name = this.record_pws_data.bacti_name
      this.form3.bact_street = this.record_pws_data.bacti_street
      this.form3.bact_city = this.record_pws_data.bacti_city
      this.form3.bact_state = this.record_pws_data.bacti_state
      this.form3.bact_zip = this.record_pws_data.bacti_zip
      this.form3.delivery_name = this.record_pws_data.delivery_name
      this.form3.delivery_street = this.record_pws_data.delivery_street
      this.form3.delivery_city = this.record_pws_data.delivery_city
      this.form3.delivery_state = this.record_pws_data.delivery_state
      this.form3.delivery_zip = this.record_pws_data.delivery_zip
      this.form4.first_name = this.record_pws_data.op_first_name
      this.form4.last_name = this.record_pws_data.op_last_name
      this.form4.cert_no = this.record_pws_data.op_cert_no
    },
    signDoc() {
      console.log("formatting data")
      const data_signature_ts = new Date().toISOString()
      const submit_data = {
        form1: this.form1,
        form2: this.form2,
        form3: this.form3,
        form4: this.form4,
        data_signature_ts: data_signature_ts,
        signame: this.signame
      }
      const serialized_data = JSON.stringify(submit_data)
      console.log(serialized_data)

      // byte array for signing
      // const encoded_message = new TextEncoder().encode(serialized_data)
      const encoded_message = textEncode(serialized_data)

      if (this.sigkey) {
        window.crypto.subtle
          // sign also hashes data - no need for seperate hash
          .sign(
            { name: "ECDSA", hash: { name: "SHA-256" } },
            this.sigkey.key.privateKey,
            encoded_message
          )
          .then((sig) => {
            console.log("verify")
            window.crypto.subtle
              .verify(
                { name: "ECDSA", hash: { name: "SHA-256" } },
                this.sigkey.key.publicKey,
                sig,
                encoded_message
              )
              .then((result) => {
                console.log("signature verified: " + result)
                console.log("sig bytes " + sig)
                console.log("sig b64 " + encode64(sig))
                this.signed_data = serialized_data
                this.data_signature = encode64(sig)
                this.data_signature_ts = data_signature_ts
                this.submitData(
                  this.signed_data,
                  this.data_signature,
                  this.b64_public_key
                )
              })
          })
      } else {
        // broken browser
        this.signed_data = serialized_data
        this.data_signature = ""
        this.b64_public_key = ""
        this.data_signature_ts = data_signature_ts
        this.submitData(
          this.signed_data,
          this.data_signature,
          this.b64_public_key
        )
      }
    },
    resendData() {
      this.submitData(
        this.signed_data,
        this.data_signature,
        this.b64_public_key
      )
    },
    submitData(signed_data, data_signature, b64_public_key) {
      // reset data submitted
      this.submitting_data = true
      this.data_submitted = false
      console.log("sending data")
      console.log(signed_data)
      console.log(data_signature)
      console.log(b64_public_key)
      const path = process.env.VUE_APP_API_URI + "/savedata"
      this.axios
        .post(path, {
          pws_id: this.$store.state.pws_id,
          signed_data: signed_data,
          data_signature: data_signature,
          public_key: b64_public_key
        })
        .then((res) => {
          this.submitting_data = false
          console.log(res)
          console.log("submit succeeded?")
          this.data_submitted = true
        })
        .catch((error) => {
          this.submitting_data = false
          console.error(error)
        })
      this.saveARData()
    },
    exportPublicKey(key) {
      // export the b64 public key
      window.crypto.subtle.exportKey("spki", key.publicKey).then((bkey) => {
        const b64key = encode64(bkey)
        const pem_key = `-----BEGIN PUBLIC KEY-----\n${b64key}\n-----END PUBLIC KEY-----`
        this.b64_public_key = pem_key
        console.log("public key b64: " + this.b64_public_key)
      })
    },
    printDiv() {
      window.print()
    },
    generateKey() {
      window.crypto.subtle
        .generateKey(
          {
            name: "ECDSA",
            namedCurve: "P-256" //can be "P-256", "P-384", or "P-521"
          },
          false, //whether the key is extractable (i.e. can be used in exportKey)
          ["sign", "verify"] //can be any combination of "deriveKey" and "deriveBits"
        )
        .then((key) => {
          //returns a keypair object
          let current_datetime = new Date()
          let formatted_date =
            current_datetime.getFullYear() +
            "-" +
            (current_datetime.getMonth() + 1) +
            "-" +
            current_datetime.getDate() +
            " " +
            current_datetime.getHours() +
            ":" +
            current_datetime.getMinutes() +
            ":" +
            current_datetime.getSeconds()
          const wrapped_key = {
            //name: this.signame,
            dt_created: formatted_date,
            key: key
          }
          set("signing_key", wrapped_key)
            .then(() => console.log("signing key set"))
            .catch((err) => console.log("error occured", err))
          this.sigkey = wrapped_key
          console.log(key)
          console.log(key.publicKey)
          console.log(key.privateKey)
          this.exportPublicKey(key)
        })
        .catch(function(err) {
          console.error(err)
        })
    },
    deleteSigningKey() {
      del("signing_key")
        .then(() => console.log("signing key deleted"))
        .catch((err) => console.log("error occured", err))
      this.sigkey = null
    },
    checkARData() {
      let fname = "ar_data_" + this.pws_id
      if (fname in localStorage) {
        console.log("setting ar_data from localStorage")
        this.ar_data = JSON.parse(localStorage.getItem(fname))
      }
    },
    loadARData() {
      console.log("loading ar data")
      if (this.ar_data) {
        console.log("data is present")
        console.log(this.form1.pws_name)
        console.log(this.ar_data.form1.pws_name)
        console.log('population: ' + this.ar_data.form1.population)
        this.form1 = this.ar_data.form1
        console.log(this.form1.pws_name)
        this.form2 = this.ar_data.form2
        this.form3 = this.ar_data.form3
        this.form4 = this.ar_data.form4
      }
    },
    saveARData() {
      console.log("saving data to local storage")
      if (this.pws_id) {
        const ar_data = {
          form1: this.form1,
          form2: this.form2,
          form3: this.form3,
          form4: this.form4,
        }
        if (this.pws_id) {
          const fname = "ar_data_" + this.pws_id
          localStorage.setItem(fname, JSON.stringify(ar_data))
        }
      }
    }
  },
  beforeDestroy() {
    console.log("beforeDestroy")
    // save data
    this.saveARData()
  }
}
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&family=Indie+Flower&display=swap");
.form-group.required > label:after {
  content: " *";
  color: red;
}

.signature {
  font-family: "Dancing Script", cursive;
  font-size: 2em;
}
</style>
